import React, { Fragment } from 'react';

import useFacilitySearch from './UseFacilitySearch';

import SearchFacility from '../../../../common/components/searches/searchFacility/SearchFacility';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const FacilitySearch = () => {
  const { onEditFacility, onAddFacility, contextSecurityState } = useFacilitySearch();

  return (
    <Fragment>
      <SearchFacility
        pageTitle={"Facility Search"}
        onEdit={onEditFacility}
        onAdd={onAddFacility}
      />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={contextSecurityState.isObjLoading} />
    </Fragment >
  );
};

export default FacilitySearch;