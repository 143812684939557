import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import useFacilityData from '../../../common/state/facility/UseFacilityData';

const BACK_TO_LABELS = {
  facilityBackToLabel: 'Back to Facility Search'
};

const useFacilityContextView = () => {
  const navigate = useNavigate();
  const { facilityState, clearObjData } = useFacilityData();

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    goToFacilitySearch();
  };

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.FACILITY_INFO);
  };

  function goToFacilitySearch() {
    clearObjData();
    navigate(NavLinks.FACILITY_SEARCH);
  };

  function getBackToLabel() {
    return BACK_TO_LABELS.facilityBackToLabel;
  };

  return {
    facilityState,
    backToLabel: getBackToLabel(),
    onBackClicked,
    onHomeClicked
  };
};

export default useFacilityContextView;