import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';

import NavLinks from '../NavLinks';

const INITIAL_VIEW_STATE = {
  text: 'Search',
  isItemSelected: false
};

const TAXONOMIES = ['FacilityDetail'];
const SCOPE = 'Facility';

const useFacilitySearch = () => {
  const navigate = useNavigate();
  const { facilityState, getFacility } = useFacilityData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  const onEditFacility = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    getFacility(facilityId);
    getContextSecurity(facilityId, TAXONOMIES, SCOPE);
    setState({ ...state, isItemSelected: true });
  };

  const onAddFacility = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
 
    navigate(NavLinks.FACILITY_ADD);
  };

  useEffect(() => {
    if (state.isItemSelected === true) {
      if (facilityState.isObjLoading !== true && facilityState.isObjLoaded === true) {
        if (facilityState.objData.facilityId > 0) {
          navigate(NavLinks.FACILITY_INFO);
        } else {
          setState(INITIAL_VIEW_STATE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, facilityState]);

  return {
    onEditFacility,
    onAddFacility,
    contextSecurityState
  };
};

export default useFacilitySearch;