import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

import FacilityContextProvider from '../../../common/state/facility/FacilityContextProvider';
import FacilityFiltersContextProvider from '../../../common/state/facility/FacilityFiltersContextProvider';
import SearchFacilityContextProvider from '../../../common/state/searchFacility/SearchFacilityContextProvider';
import PoolConfigurationContextProvider from '../../../common/state/poolConfiguration/PoolConfigurationContextProvider';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import FacilityContextView from './FacilityContextView';
import FacilitySearch from './facilitySearch/FacilitySearch';
import FacilityInfo from './facilityInfo/FacilityInfo';
import FacilityPoolsGrid from './facilityPools/facilityPoolsGrid/FacilityPoolsGrid';
import FacilityAdd from './facilityAdd/FacilityAdd';
import FacilityPoolsDetail from './facilityPools/facilityPoolsAddEdit/FacilityPoolsDetail';


const ContextRoutes = ({navRoutes}) => (
  <FacilityContextView>
    <Routes>
      <Route path={navRoutes['FACILITY_INFO']?.path} 
        element={
          <SecuredRouteElement 
            navRoute={navRoutes['FACILITY_INFO']} 
            editElement={<FacilityInfo />} />
          }
      />
      <Route path={navRoutes['FACILITY_POOLS']?.path} 
        element={
          <SecuredRouteElement 
            navRoute={navRoutes['FACILITY_POOLS']} 
            editElement={<FacilityPoolsGrid />} />
          }
      />
      <Route path={navRoutes['FACILITY_POOLS_DETAIL']?.path} 
        element={
          <SecuredRouteElement 
            navRoute={navRoutes['FACILITY_POOLS_DETAIL']} 
            editElement={<FacilityPoolsDetail />} />
          }
      />
    </Routes>
  </FacilityContextView>
);

const FacilityRoutes = () => {
  const {navRoutes, isReadyToRoute} = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['FACILITY_SEARCH']?.path} 
        element={
          <SecuredRouteElement 
            navRoute={navRoutes['FACILITY_SEARCH']} 
            editElement={<FacilitySearch />} />
          }
      />
      <Route path={navRoutes['FACILITY_ADD']?.path} 
        element={
          <SecuredRouteElement 
            navRoute={navRoutes['FACILITY_ADD']} 
            editElement={<FacilityAdd />} />
          }
      />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['FACILITY_SEARCH']?.route} replace />} />
    </Routes>
  );
};

const Facility = () => (
  <FacilityContextProvider>
    <FacilityFiltersContextProvider>
      <SearchFacilityContextProvider>
        <PoolConfigurationContextProvider>
          <FacilityRoutes />
        </PoolConfigurationContextProvider>
      </SearchFacilityContextProvider>
    </FacilityFiltersContextProvider>
  </FacilityContextProvider>
);

export default Facility;