import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DeleteButton = ({ pool, onDelete, allowedToDelete }) => {
  if (allowedToDelete === true) {
    return (
      <Fragment>
        <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onDelete(e, pool)}>
          <DeleteIcon />
        </button>
      </Fragment>
    );
  } else {
    return (
      <Fragment />
    );
  }
};

const DetailTableRow = ({ poolConfig }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>
        {poolConfig.course?.courseCode}
      </span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Course</div>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>{poolConfig.course?.courseCode || <span>&nbsp;</span>}</div>
        <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Number of Lanes</div>
        <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{poolConfig.numberOfLanes}</div>
      </div>
    </div>
  </div>
);

const DetailTable = ({ pool }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(pool.poolConfiguration) && pool.poolConfiguration.map((poolConfig, i) =>
      <DetailTableRow key={i} poolConfig={poolConfig} />)}
  </div>
);

const GridRow = ({ pool, onEdit, onDelete, allowedToDelete, expandedId, onClick }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow} key={pool.facilityPoolId}>
        <div className={global.SmallTableRowHead}><span className='hidden-xs'>{pool.poolName && pool.poolName.trim() !== '' ? pool.poolName : <span>&nbsp;</span>}</span>&nbsp;
          <button className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, pool.poolId)}>
            {expandedId === pool.poolId ? <HideIcon /> : <ShowIcon />}
          </button>
          <DeleteButton
            pool={pool}
            onDelete={onDelete}
            allowedToDelete={allowedToDelete} />
          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, pool.poolId)}>
            <EditIcon />
          </button>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(" ")}>Pool Name</div>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(" ")}>{pool.poolName && pool.poolName.trim() !== '' ?
              pool.poolName : <span>&nbsp;</span>}</div>
            <div className={['col-xs-6 col-sm-6', global.SmallTableRowLabels].join(" ")}>Pool Dimensions</div>
            <div className={['col-xs-6 col-sm-6', global.SmallTableRowData].join(" ")}>{pool.poolDimensions?.dimensionName}</div>
          </div>
        </div>
      </div >
      {Array.isArray(pool.poolConfiguration) && pool.poolConfiguration.length > 0 && expandedId === pool.poolId &&
        <DetailTable pool={pool} />}
    </Fragment >
  );
}

const SmallGrid = ({ poolsArray, isLoaded, isLoading, onEdit, onDelete, allowedToDelete, expandedId, onClick }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
      {isLoaded !== true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              {isLoading === true ?
                <div className={global.SmallTableRowLabels}>Loading...</div> :
                <div className={global.SmallTableRowLabels}>No Pools</div>}
            </div>
          </div>
        ) :
        Array.isArray(poolsArray) && poolsArray.length > 0 ?
          poolsArray.map((pool, i) =>
            <GridRow
              key={i}
              pool={pool}
              onEdit={onEdit}
              onDelete={onDelete}
              allowedToDelete={allowedToDelete}
              expandedId={expandedId}
              onClick={onClick} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>
                &nbsp;
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>
                  No Pools
                </div>
              </div>
            </div>)
      }
    </div>
  </Fragment>
);

export default SmallGrid;