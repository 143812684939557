import React, { Fragment } from 'react';

import useFacilityContextView from './UseFacilityContextView';

import NavLinks from './NavLinks';

import Headings from '../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';
import PageNotFound from '../../../common/components/PageNotFound';

import global from '../../../common/components/GlobalStyle.module.css';

const FacilityContextView = ({ children }) => {
  const {
    facilityState,
    backToLabel,
    onBackClicked,
    onHomeClicked
  } = useFacilityContextView();

  if (!facilityState || Object.keys(facilityState.objData).length === 0) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              View/Edit Facility
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={onBackClicked}>
            <LeftArrowIcon />  {backToLabel}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <p className={global.HeaderText}><b>Facility Name:</b> {facilityState.objData.facilityName} -- {facilityState.objData.city}, {facilityState.objData.stateCode}, {facilityState.objData.postalCode}</p>
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="FacilityDetail" renderPillsOnly={true} />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="FacilityDetail" homeRoute={NavLinks.FACILITY_INFO} renderPillsOnly={true} />
        </div>
      </div>
      {children}
    </Fragment >
  );
};

export default FacilityContextView;