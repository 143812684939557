import React, { Fragment } from 'react';

import useFacilityAdd from './UseFacilityAdd';

import AddFacility from '../../../../common/components/searches/searchFacility/addFacility/AddFacility';

import NavLinks from '../NavLinks';

const FacilityAdd = () => {
  // eslint-disable-next-line no-empty-pattern
  const { } = useFacilityAdd();

  return (
    <Fragment>
      <AddFacility
        saveUrl={NavLinks.FACILITY_INFO}
        cancelUrl={NavLinks.FACILITY_SEARCH}
        orgUnitId={undefined} />
    </Fragment >
  );
};

export default FacilityAdd;