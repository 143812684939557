import React, { Fragment } from 'react';

import useFacilityPoolsAdd from './UseFacilityPoolsDetail';

import NavLinks from '../../NavLinks';

import AddEditFacilityPool from '../../../../../common/components/searches/searchFacility/addEditPool/AddEditFacilityPool';

const FacilityPoolsDetail = () => {
  // eslint-disable-next-line no-empty-pattern
  const { } = useFacilityPoolsAdd();

  return (
    <Fragment>
      <AddEditFacilityPool
        saveUrl={NavLinks.FACILITY_POOLS}
        cancelUrl={NavLinks.FACILITY_POOLS}
        showFacilityInfo={false}
      />
    </Fragment>
  );
};

export default FacilityPoolsDetail;