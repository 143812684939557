import React, { Fragment } from 'react';

import FacilityPoolsDeleteConfirmation from './FacilityPoolsDeleteConfirmation';
import LargeGrid from './LargeGrid';
import SmallGrid from './SmallGrid';

import useFacilityPoolsGrid from './UseFacilityPoolsGrid';
import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal'
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../../common/utils/Constants';

const FacilityPoolsGrid = () => {
  const {
    gridState,
    modalState,
    facilityState,
    onAddPool,
    onEditPool,
    onDeletePool,
    onModalCanceled,
    onClickDeletePool
  } = useFacilityPoolsGrid();
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Facility Pools</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={onAddPool}>Add Pool</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <LargeGrid
            allowedToDelete={true}
            poolsArray={gridState.poolsArray}
            isLoading={gridState.isLoading}
            isLoaded={gridState.isLoaded}
            onEdit={onEditPool}
            onDelete={onDeletePool}
            expandedId={expandedId}
            onClick={onClick} />
          <SmallGrid
            allowedToDelete={true}
            poolsArray={gridState.poolsArray}
            isLoading={gridState.isLoading}
            isLoaded={gridState.isLoaded}
            onEdit={onEditPool}
            onDelete={onDeletePool}
            expandedId={expandedId}
            onClick={onClick} />
        </div>
      </div>
      <FacilityPoolsDeleteConfirmation
        modalState={modalState}
        onModalCanceled={onModalCanceled}
        onClickDeletePool={onClickDeletePool} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={facilityState.isArrayLoading || facilityState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={facilityState.isSaving} />
    </Fragment>
  );
};

export default FacilityPoolsGrid;