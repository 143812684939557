import React, { Fragment } from 'react';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

const FacilityPoolsDeleteConfirmation = ({ modalState, onModalCanceled, onClickDeletePool }) => (
  <Fragment>
    <PopUpModal
      title={modalState.modalTitle}
      displayPopUp={modalState.displayPopup}
      widthPct={90}
      maxWidth={720}
      onModalCanceled={onModalCanceled}>
      <div className="row">
        <div className="col-xs-12">
          <p><b>Pool Number / Name:</b> {modalState.poolName}</p>
          <p><b>Pool Dimensions:</b> {modalState.poolDimensions}</p>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <PrimaryButton type="button" onClick={(e) => { onClickDeletePool(e, modalState.poolId) }}>Yes, Delete</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
        </div>
      </div>
    </PopUpModal>
  </Fragment>
);

export default FacilityPoolsDeleteConfirmation;