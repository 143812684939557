import React, { Fragment } from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DeleteButton = ({ pool, onDelete, allowedToDelete }) => {
  if (allowedToDelete === true) {
    return (
      <Fragment>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, pool)}>
          <DeleteIcon />
        </button>
      </Fragment>
    );
  } else {
    return (
      <Fragment />
    );
  }
};

const DetailTableRow = ({ poolConfig }) => {
  return (
    <tr>
      <td>{poolConfig.course?.courseCode}</td>
      <td>{poolConfig.numberOfLanes}</td>
    </tr>
  )
}

const DetailTable = ({ pool }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Course</th>
        <th>Number of Lanes</th>
      </tr>
    </thead>
    <tbody>
      {pool.poolConfiguration.map((poolConfig, i) => <DetailTableRow poolConfig={poolConfig} key={i} />)}
    </tbody>
  </table>
);

const TableRow = ({ pool, onEdit, onDelete, allowedToDelete, expandedId, onClick }) => {
  return (
    <Fragment>
      <tr key={pool.facilityPoolId}>
        <td>{pool.poolName}</td>
        <td>{pool.poolDimensions?.dimensionName}</td>
        <td>
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, pool.poolId)}>
            <EditIcon />
          </button>
          <DeleteButton
            pool={pool}
            onDelete={onDelete}
            allowedToDelete={allowedToDelete} />
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, pool.poolId)}>
            {expandedId === pool.poolId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {Array.isArray(pool.poolConfiguration) && pool.poolConfiguration.length > 0 && expandedId === pool.poolId &&
        <tr className={global.Expanded}>
          <td colSpan="6">
            <DetailTable pool={pool} />
          </td>
        </tr>}
    </Fragment>
  );
}

const LargeGrid = ({ allowedToDelete, poolsArray, isLoading, isLoaded, onEdit, onDelete, expandedId, onClick }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
      <thead>
        <tr>
          <th>Pool Number / Name</th>
          <th>Pool Dimensions</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoaded !== true
          ? (<tr>
            {isLoading === true ?
              <td colSpan="3">Loading...</td>
              : <td colSpan="3">No Pools</td>}
          </tr>)
          : Array.isArray(poolsArray) && poolsArray.length > 0 ?
            poolsArray.map((pool, i) =>
              <TableRow
                key={i}
                pool={pool}
                onEdit={onEdit}
                onDelete={onDelete}
                allowedToDelete={allowedToDelete}
                expandedId={expandedId}
                onClick={onClick} />)
            : (<tr>
              <td colSpan="3">No Pools</td>
            </tr>)}
      </tbody>
    </table>
  </Fragment>
);

export default LargeGrid;