import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Outlet } from '../common/wrappers/ReactRouterDom';

import useNavRoutes from '../common/state/security/UseNavRoutes';

import Facility from './views/facility/Facility';

import LoadingModal from '../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../common/components/security/SecuredRouteElement';
import PageNotFound from '../common/components/PageNotFound';
import UnexpectedError from '../common/components/UnexpectedError';
import ErrorBoundary from '../common/components/errors/ErrorBoundary';
import ErrorBoundaryFallback from '../common/components/errors/ErrorBoundaryFallback';

const AppRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Routes>
        <Route path={navRoutes['HOME']?.path} element={<Fragment><Outlet /></Fragment>}>
          <Route path={navRoutes['FACILITY_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['FACILITY_ROOT']}
                editElement={<Facility />} />
            }
          />

          <Route path="500" element={<UnexpectedError />} />
          <Route path="/*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;