import useFacilityData from '../../../../../common/state/facility/UseFacilityData';

const useFacilityPoolsDetail = () => {
  const { facilityState } = useFacilityData();

  return {
    facilityState
  };
};

export default useFacilityPoolsDetail;