import React, { Fragment } from 'react';
import useFacilityInfo from './UseFacilityInfo';

import FacilityForm from '../../../../common/components/searches/searchFacility/addFacility/components/FacilityForm';

import Headings from '../../../../common/components/headings/Headings';
import AddressValidation from '../../../../common/components/addressValidation/AddressValidation';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const FacilityInfo = () => {
  const {
    formState,
    errorState,
    facilityState,
    modalState,
    onFormValueChanged,
    resetForm,
    onValueTextPairChanged,
    onDeleteFacility,
    onClickDeleteFacility,
    onModalCanceled,
    handleSubmitProxy,
    setFormData,
    validateState
  } = useFacilityInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Facility Info</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmitProxy} noValidate className="usas-extra-bottom-margin">
        <FacilityForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          onValueTextPairChanged={onValueTextPairChanged} />
        {formState.isInternational === false
          ? <AddressValidation formState={formState} setFormData={setFormData} />
          : <Fragment />}
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteFacility}>Delete Facility</SecondaryButton>
          </div>
        </div>
      </form>
      {validateState.isValidating === true && (
        <PopUpModal
          displayPopUp={true}
          maxWidth={250}
          title="Validating..."
          widthPct={50} />
      )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={facilityState.isArrayLoading || facilityState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={facilityState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Facility Name:</b> {modalState.facilityName}</p>
            <p><b>Facility Location:</b> {modalState.facilityCity}, {modalState.facilityStateCode} {modalState.facilityPostalCode}</p>
            <p><b>Number of Pools:</b> {modalState.numOfPools}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onClickDeleteFacility}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment >
  )
}

export default FacilityInfo;