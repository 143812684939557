import React from 'react';

import ClubAffiliationTypeContextProvider from '../common/state/clubAffiliationType/ClubAffiliationTypeContextProvider'
import PoolDimensionsContextProvider from '../common/state/poolDimensions/PoolDimensionsContextProvider';
import CourseContextProvider from '../common/state/course/CourseContextProvider';

const AppStateContextProviders = ({ children }) => {
  return (
    <ClubAffiliationTypeContextProvider>
      <PoolDimensionsContextProvider>
        <CourseContextProvider>
          {children}
        </CourseContextProvider>
      </PoolDimensionsContextProvider>
    </ClubAffiliationTypeContextProvider>
  );
};
export default AppStateContextProviders;