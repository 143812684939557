import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import useFacilityData from '../../../../../common/state/facility/UseFacilityData';

import Constants from '../../../../../common/utils/Constants';

import NavLinks from '../../NavLinks';

const useFacilityPoolsGrid = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { facilityState, getFacility, deleteFacilityPool, confirmSave } = useFacilityData();
  const [gridState, setGridState] = useState(getInitialGridState);
  const [modalState, setModalState] = useState(getInitialModalState);

  const onAddPool = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.FACILITY_POOLS_DETAIL, { state: { facilityId: facilityState.objData.facilityId } });
  };

  const onEditPool = (e, poolId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.FACILITY_POOLS_DETAIL, { state: { facilityId: facilityState.objData.facilityId, poolId: poolId } });
  };

  const onDeletePool = (e, pool) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopup: true,
      poolId: pool.poolId,
      poolName: pool.poolName,
      poolDimensions: pool.poolDimensions.dimensionName
    });
  };

  const onClickDeletePool = (e, poolId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (facilityState.isSaving === false) {
      setModalState(getInitialModalState);
      deleteFacilityPool(poolId);
    }
  };

  const onModalCanceled = () => {
    setModalState(getInitialModalState);
  };

  useEffect(() => {
    if (location.state && location.state.facilityId) {
      getFacility(location.state.facilityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (facilityState.isSaved === true && facilityState.isSaving === false) {
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState]);

  useEffect(() => {
    if (facilityState.isObjLoaded === true) {
      const poolsArray = [...facilityState.objData.facilityPool].map((pool) => {
        return {
          poolId: pool.facilityPoolId,
          poolName: pool.facilityPoolName,
          poolConfiguration: pool.poolConfiguration,
          poolDimensions: pool.poolDimensions
        };
      });

      setGridState({
        ...gridState,
        isLoading: false,
        isLoaded: true,
        poolsArray
      });
      setModalState(getInitialModalState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityState.objData]);

  function getInitialGridState() {
    return {
      isLoaded: false,
      isLoading: true,
      poolsArray: []
    };
  };

  function getInitialModalState() {
    return {
      modalTitle: 'Delete this Pool from the Facility?',
      displayPopup: false,
      poolId: Constants.DEFAULT_ID,
      poolName: '',
      poolDimensions: ''
    };
  };

  return {
    gridState,
    modalState,
    facilityState,
    onAddPool,
    onEditPool,
    onDeletePool,
    onModalCanceled,
    onClickDeletePool
  };
};

export default useFacilityPoolsGrid;