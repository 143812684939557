const NavLinks = {
  FACILITY_ROOT: '/facility',
  FACILITY_SEARCH: '/facility/search',
  FACILITY_INFO: '/facility/info',
  FACILITY_ADD: '/facility/add',
  FACILITY_POOLS: '/facility/pools',
  FACILITY_POOLS_DETAIL: '/facility/pools/detail',
  FACILITY_CLUB_LINK: '/facility/clublink',
};

export default NavLinks;